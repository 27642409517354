import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'

Vue.use(Vuex)
const state = {
  is: false, // 判断是否登录，登录true,没有fals
  routerArr: [], // 静态+ 动态
  asyncRoutes: [
    // 动态路由默认首页

  ]
}
export default new Vuex.Store({
  state,
  modules: {
  },

  mutations: {
    // 点击了登录退出
    CHANGESTORE (state, bool) {
      state.is = bool
      window.sessionStorage.setItem('bool',bool)
    },
    // 存在路由路径
    HEADER (state, data) {
      state.routerArr = data
    }
  },
  actions: {
    // 修改状态
    updateStoreChange (state, context) {
      state.commit('CHANGESTORE', context)
    },
    updateRouterArr (state,context ) {
      state.commit('HEADER', context)
    },
  },
  getters: {
    // updateStore () {
    //   return state.is
    // },
    // updateNav () {
    //   return state.routerArr
    // }
  }
})
