// 处理路由拦截器 导航守卫
import router from './router'
import progresss from 'nprogress'
import 'nprogress/nprogress.css'
import userAddRoute from './config/router.dynamic'
import store from './store/index'
// 全局前置守卫  当 路由发生变化时 这个方法里的回调函数就会执行
//定义放行白名单


// 登录页
const loginRoutePath = '/toLogin'
// router.beforeEach(function (to, from, next) {
//   progresss.start() // 开启进度条
//   // 权限拦截 认为有token 让过去 没token不让过 webArticle
//   if (to.path == "/"   || to.path == "/web" || to.path == "/webMails"|| to.path == "/webArticle" || to.path == "/webArticleList"|| to.path == "/webOtherArticleList" || to.path == loginRoutePath ) {
//     next() // 直接放过
//   } else {
//     const token = window.localStorage.getItem('token')
//     //判断token是否存在，如果存在写入登录状态，拉取动态路由参数进行重写
//     if (token) {
//       store.dispatch('updateStoreChange', true) //写入状态
//       userAddRoute(window.sessionStorage.getItem('power'))  //写入动态路由（菜单）数据
//       next()
//     } else {
//       next('/') // 跳转到登录页
//     }
//   }
// })
router.afterEach(() => {
  // setTimeout(() => progresss.done(), 1000)
  progresss.done()
  // 关闭进度条

})
