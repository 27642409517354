const DEFULE_BACK_URL='http://127.0.0.1/back/'
const DEFULE_URL='http://cdr.jiubaojiankang.cn/'
const uploadImage= DEFULE_BACK_URL+'upload/uploadImage'
const uploadEditorImage= DEFULE_BACK_URL+'upload/uploadEditorImage'
const uploadEditorVideo=DEFULE_BACK_URL+'upload/uploadEditorVideo'
const uploadVideo=DEFULE_BACK_URL + 'upload/uploadVideo'
const IMAGE_URL_TEMP = DEFULE_URL+'websites/temp/'
const CONFIG_IMAGE_URL = DEFULE_URL+'websites/configImage/'
const ARTICLE_URL =DEFULE_URL+ 'websites/article/'
export default{
	uploadImage,
	uploadVideo,
	IMAGE_URL_TEMP,
	CONFIG_IMAGE_URL,
	uploadEditorImage,
	uploadEditorVideo,
	ARTICLE_URL
}