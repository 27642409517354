import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
/**
 * 静态路由
 * @type { *[] }
 */
export const constantRoutes = [
  {
    path:'/',
    name:'index',
    component:() => import( '@/views/index/index'),
    meta: {
      title: '浙江共生店信息科技有限公司'
    }
  },
  {
    path:'/aboutAs',
    name:'aboutAs',
    component:() => import( '@/views/aboutAs/index'),
  },
  {
    path:'/product',
    name:'product',
    component:() => import( '@/views/product/index'),
  },
  {
    path:'/news',
    name:'news',
    component:() => import( '@/views/news/index'),
  },
  {
    path:'/contactUs',
    name:'contactUs',
    component:() => import( '@/views/contactUs/index'),
  }
]

/**
 *
 * 动态路由:后台数据返回注意返回格式
 *
 */
export const asyncRoutes = [
]
const createRouter = () => new Router({
  scrollBehavior: () => ({ y: 0 }),
  mode:'history',
  routes: constantRoutes
})

const router = createRouter()

// 这个是重置路由用的，很有用，别看这么几行代码
export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
