const validateChinaName=(rule,value,callback)=>{
	let patter= new RegExp(/^[\u4e00-\u9fa5]+$/)
	if(patter.test(value)){
		callback();
	}else{
		return callback(new Error('请输入中文名称'))
	}
}
const validataEngPath=(rule,value,callback)=>{
	let patter= new RegExp(/^[a-zA-Z0-9]+$/)
	if(patter.test(value)){
		callback();
	}else{
		return callback(new Error('请输入英文地址'))
	}
}
const validatePhoneName=(rule,value,callback)=>{
	let patter= new RegExp(/^1[3|4|5|7|8][0-9]\d{8}$/)
	if(patter.test(value)){
		callback();
	}else{
		return callback(new Error('请输入正确的手机格式'))
	}
}
export default{
	menu:{
		firstMenuName:[{
			required:true,
			message:'菜单名称不能为空'
		},{
			validator:validateChinaName,
			tigger:"blur"
		}],
		secondMenuName:[{
			required:true,
			message:'菜单名称不能为空'
		},{
			validator:validateChinaName,
			tigger:"blur"
		}],
		secondMenuPath:[{
			required:true,
			message:'接口地址不能为空'
		},{
			validator:validataEngPath,
			tigger:"blur"
		}]
	},
	navMenu:{
		navMenuName:[{
			required:true,
			message:'菜单名称不能为空'
		},{
			validator:validateChinaName,
			tigger:"blur"
		}],
		navMenuPath:[{
			required:true,
			message:'访问栏目不能为空'
		}],
	},
	admin:{
		adminName:[{
			required:true,
			message:'管理员姓名不能为空'
		},{
			validator:validateChinaName,
			tigger:"blur"
		}],
		adminLogin:[{
			required:true,
			message:'请输入管理员帐号'
		},{
			validator:validataEngPath,
			tigger:"blur"
		}],
		adminPassword:[{
			required:true,
			message:'请输入管理员密码'
		},{
			validator:validataEngPath,
			tigger:"blur"
		}],
		adminPhone:[{
			required:true,
			message:'请输入管理员手机号码'
		},{
			validator:validatePhoneName,
			tigger:"blur"
		}],
	},
	mails:{
		adminId:[{
			required:true,
			message:'请选择送达邮箱'
		}],
		mailsTitle:[{
			required:true,
			message:'请输入标题'
		}],
		peopleName:[{
			required:true,
			message:'请输入联系人'
		}],
		submitPhone:[{
			required:true,
			message:'请输入联系方式'
		}],
		submitValue:[{
			required:true,
			message:'请输入内容'
		}],
	},
	blogroll:{
		blogrollName:[{
			required:true,
			message:'链接名称不能为空'
		},{
			validator:validateChinaName,
			tigger:"blur"
		}],
		blogrollGenreId:[{
			required:true,
			message:'请选择链接栏目'
		}],
		blogrollUrl:[{
			required:true,
			message:'请输入链接地址'
		}],
	}
}