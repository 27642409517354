import Vue from 'vue'
import VueRouter from 'vue-router'

import { constantRoutes } from '@/config/router.config'
Vue.use(VueRouter)

const routes = constantRoutes

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new VueRouter({
  mode: 'history',
  routes: routes
})
